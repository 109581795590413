@import '~@angular/material/theming';

$mayo-blue: (
    50 : #e0e8f4,
    100 : #b3c5e4,
    200 : #809ed2,
    300 : #4d77c0,
    400 : #265ab3,
    500 : #003da5,
    600 : #00379d,
    700 : #002f93,
    800 : #00278a,
    900 : #001a79,
    A100 : #a7b2ff,
    A200 : #7486ff,
    A400 : #415aff,
    A700 : #2744ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$warn-env: (
  500 : #FFA500,
  contrast: (
      500 : #ffffff,
  )
);


@include mat-core();
$mlp-theme-primary: mat-palette($mayo-blue, 500);
$mlp-theme-accent: mat-palette($mayo-blue, 200);
$mlp-theme-warn: mat-palette($warn-env, 500);
$mlp-theme: mat-light-theme($mlp-theme-primary, $mlp-theme-accent, $mlp-theme-warn);
@include angular-material-theme($mlp-theme);


$primary: mat-color($mlp-theme-primary);
$accent: mat-color($mlp-theme-accent);
$warn: mat-color($mlp-theme-warn);

$mlp-darkfont: mat-palette($mayo-blue, 900);
$darkfont: #281C5C;

$mlp-darkbutton: mat-palette($mayo-blue, 800);
$darkbutton: mat-color($mlp-darkbutton);


$mlp-red: #EB7D7C;
$mlp-green: #90ED96;
$mlp-pink: #EDA99C;
$mlp-blue: #00B9EC;
$mlp-dark-gray: #999A9A;
$mlp-light-gray: #D3D3D3;

/*$mlp-green: #A1D163;
$mlp-darkgreen: #15778A;
$mlp-purple: #907EAB;
$mlp-darkblue: #281C5C;
$mlp-aqua: #73B9C2;*/
